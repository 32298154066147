<template>
    <div>
        <page-header
            :title="$t('pages.closeAccountInRescission.title')"
            show-close
            @onClose="returnToRoot"
        />
        <div
            class="alert alert-warning text-start mt-2 mx-4"
            v-if="errorText"
            role="alert"
        >
            <span v-html="errorText" />
        </div>

        <div class="container">
            <div v-html="$t('pages.closeAccountInRescission.body')" />
            <form
                @submit.prevent="handleSubmit"
                class="mt-4"
            >
                <label
                    for="confirm-rescission"
                    class="d-flex flex-row"
                >
                    <span class="me-2">
                        <input
                            type="checkbox"
                            name="confirm-rescission"
                            id="confirm-rescission"
                            v-model="confirmRescission"
                        >
                    </span>
                    <span v-html="$t('pages.closeAccountInRescission.confirmCheckboxLabel')" />
                </label>
                <p class="mt-3">
                    {{ $t('pages.closeAccountInRescission.logOutNotice') }}
                </p>
                <base-button
                    :disabled="!confirmRescission"
                    :submitting="isSubmitting"
                    class="btn-primary mt-2"
                >
                    {{ $t('pages.closeAccountInRescission.confirmCta') }}
                </base-button>
            </form>
        </div>
    </div>
</template>

<script>
    import { navigation } from '@/mixins/navigation'
    import PageHeader from '@/components/PageHeader'
    import BaseButton from '@/components/base/BaseButton'
    import { closeAccountInRescission } from '@/services/api'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { RoutePaths } from '@/routes/router'
    import { logger } from '@/utils/logger'

    export default {
        name: 'CloseAccountInRescission',
        mixins: [
            navigation, // get returnToRoot method
        ],
        components: {
            'page-header': PageHeader,
            'base-button': BaseButton,
        },
        mounted() {
            this.$logEvent('view_close_cash_out_in_rescission')
        },
        data() {
            return {
                errorText: null,
                confirmRescission: false,
                isSubmitting: false,
                isSubmitted: false,
            }
        },
        methods: {
            async handleSubmit() {
                try {
                    this.isSubmitting = true
                    const response = await closeAccountInRescission()
                    if (response.data.success) {
                        this.isSubmitted = true
                        this.$logEvent('close_account_in_rescission_period')
                        // logout user
                        logger.info('Removing current jwt from session storage')
                        appSessionStorage.removeItem(localStorageKey.jwtTokens)
                        try {
                            logger.info('Navigation to login screen')
                            await this.$router.replace({ path: RoutePaths.LOGIN })
                        } catch (navigationError) {
                            this.errorText = this.$t('global.errors.generic')
                            logger.error(`vue-router error: ${navigationError}`)
                        }
                    } else {
                        logger.info(`Failed to close the account: ${response.data.error}`)
                        this.errorText = response.data.error
                    }
                } catch (error) {
                    logger.info(`Failed to close the account: ${error.message}`)
                    this.errorText = ApiErrorHandler(error)
                } finally {
                    this.isSubmitting = false
                }
            },
        },
    }
</script>
